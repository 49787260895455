body {
  background-color: #459cd3;
}

@mixin flex-centered-column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@keyframes ripple {
  from {
      transform: scale(0);
      opacity: 0;
  }
}

.container-fluid {
  position: relative;
  z-index: 0;
  height: 100vh;
  display: flex;
  overflow: hidden;
  background-image: linear-gradient(180deg, #459cd3 8.33%, #448ab7 91.94%);

  .center {
      @include flex-centered-column();
      margin: auto;

      > div {
          margin-top: 20px;
      }

      .children-outlet {
          @include flex-centered-column();
          width: 300px;

          > div:not(.ripples) {
              width: 100%;
          }

          .ripples {
              position: absolute;
              z-index: -1;

              .ripple {
                  animation: ripple normal 1s
                      cubic-bezier(0.58, 0.23, 0, 1.43);
                  min-width: 464px;
                  min-height: 464px;
                  padding: 128px;
                  border-radius: 50%;
                  background-color: rgba(0, 182, 241, 0.4);

                  &.magma {
                      background-color: rgba(0, 182, 241, 1);
                  }
              }
          }
      }
  }
}

.title {
  font-size: 30px;
  font-weight: 500;
  color: #fff;
  line-height: 32px;
  text-align: center;
  max-width: 280px;
}
